import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import ChildrenSeats from "./ChildrenSeats";

const AddPayment = lazy(() => import("./AddPayment"));
const HandleStatus = lazy(() => import("./HandleStatus"));
const UpdateBooking = lazy(() => import("./UpdateBooking"));
const DuplicateBooking = lazy(() => import("./DuplicateBooking"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));
const PaymentCards = lazy(() => import("./PaymentCards"));


export { default } from "./AppModal";

export type ModalMapper = {
	[key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
	HANDLE_STATUS: <HandleStatus />,
	ADD_PAYMENT: <AddPayment />,
	UPDATE_BOOKING: <UpdateBooking />,
	DUPLICATE_BOOKING: <DuplicateBooking />,
	CHILDREN_SEATS: <ChildrenSeats />,
	CHANGE_PASSWORD: <ChangePassword />,
	CONFIRMATION_FORM: <ConfirmationForm />,
	CONFIRMATION_MODAL: <ConfirmationModal />,
	PAYMENT_CARDS: <PaymentCards />,

};
